import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Submenu from "./Submenu"

const PompyCiepla = () => {
  return (
    <div id="pompy_ciepla">
      <div className="ozeElement">
        <StaticImage
          src="../../assets/images/oze/pompa_ciepla.png"
          alt="pompy_ciepla"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <div className="blackBorderLeft">
            <h3>POMPY CIEPŁA</h3>
            <p>
              Pompa ciepła to energooszczędne urządzenie, zapewniające
              ogrzewanie i chłodzenie pomieszczeń oraz grzanie wody użytkowej.
              Zasada działania pompy polega na pozyskaniu energii zmagazynowanej
              w powietrzu. Nasza firma oferuje audyt i dobór urządzenia, montaż
              wraz z podłączeniem do instalacji oraz serwis. W naszej ofercie
              znajdują się urządzenia marek: ROTENSO, LG, DAIKIN czy FOLANSI.
            </p>
          </div>
          <Submenu galery={true}>pompy_ciepla</Submenu>
        </div>
        <div className="text-holder2" lang="en">
          <div className="blackBorderLeft">
            <p>
              Pompa ciepła, pod względem energetycznym, ma do 5 razy mniejsze
              zapotrzebowanie na energię niż inne źródła ciepła. Pozwala na
              zredukowanie kosztów ogrzewania, a w połączeniu z instalacjami
              fotowoltaicznymi osiąga pełny efekt ekologiczny i finansowy
              (darmowe ogrzewanie) – pompy są zasilane energią odnawialną i
              charakteryzuje je niska emisyjność CO2.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PompyCiepla
