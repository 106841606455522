import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
//My Components
import Top from "../components/Top"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"
import OzeMenu from "../components/oze/OzeMenu"
import PompyCiepla from "../components/oze/PompyCiepla"
import Fotowoltaika from "../components/oze/Fotowoltaika"
import Klimatyzacja from "../components/oze/Klimatyzacja"
import SmartDom from "../components/oze/SmartDom"
import Kompensatory from "../components/oze/Kompensatory"
import Seo from "../components/Seo"

// Query for Top image and Contact Form background image
export const query = graphql`
  {
    form: file(
      name: { eq: "oze" }
      sourceInstanceName: { eq: "formBackgrounds" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    top: file(name: { eq: "oze" }, sourceInstanceName: { eq: "topImages" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`

const EkologicznaEnergia = () => {
  const data = useStaticQuery(query)
  const imgForm = getImage(data.form)
  const imgTop = getImage(data.top)
  return (
    <div id="oze" className="page ozePage">
      <Seo title="Ekologiczna energia" />
      <Top image={imgTop} />
      <OzeMenu />
      <PompyCiepla />
      <Fotowoltaika />
      <Klimatyzacja />
      <SmartDom />
      <Kompensatory />
      <ContactForm backgroundImage={imgForm} nrTel="781 144 999" />
      <Footer nrTel="781 144 999" email="oze@eka.pl" />
    </div>
  )
}

export default EkologicznaEnergia
