import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Submenu from "./Submenu"

const Fotowoltaika = () => {
  return (
    <div id="fotowoltaika">
      <div className="ozeElement">
        <StaticImage
          src="../../assets/images/oze/fotowoltaika.png"
          alt="fotowoltaika"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <div className="blackBorderLeft">
            <h3>FOTOWOLTAIKA</h3>
            <p>
              Instalacja, pozwalająca na przetworzenie promieniowana słonecznego
              na energię elektryczną. Nasza firma zajmuje się sprzedażą oraz
              montażem instalacji fotowoltaicznych. W skład pakietu wchodzą:
              panele fotowoltaiczne, falowniki, konstrukcje montażowe, przewody,
              zabezpieczenia, doradztwo oraz specjalistyczna pomoc w
              uruchomieniu instalacji oraz jej obsłudze. Dzięki tej instalacji w
              Twoim domu czy zakładzie pracy będziesz korzystać z ekologicznej
              energii! Pracujemy na urządzeniach marki: JINKO Solar, LONGI
              Solar, JUST Solar, DAH Solar, AFORE oraz HUAWEI.
            </p>
          </div>
          <Submenu galery={true} film={true}>
            fotowoltaika
          </Submenu>
        </div>
      </div>
    </div>
  )
}

export default Fotowoltaika
