import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Submenu from "./Submenu"

const Klimatyzacja = () => {
  return (
    <div id="klimatyzacja">
      <div className="ozeElement">
        <StaticImage
          src="../../assets/images/oze/klimatyzacja.png"
          alt="klimatyzacja"
          placeholder="tracedSVG"
          className="elementBackground"
        />

        <div className="text-holder" lang="en">
          <div className="blackBorderLeft">
            <h3>KLIMATYZACJA</h3>
            <p>
              Ma za zadanie utrzymanie w pomieszczeniu zadanych warunków
              klimatycznych. Przede wszystkim – zapewnienie odpowiedniego
              zakresu temperatury oraz wilgotności powietrza. Pomaga stworzyć
              dogodne warunki do pracy i odpoczynku. Sprawdzi się w biurach,
              zakładach pracy, przestrzeniach publicznych, ale także w domu.
              Jest to proste i intuicyjne w obsłudze urządzenie. Oferujemy
              urządzenia renomowanych marek: ROTENSO, LG…
            </p>
          </div>
          <Submenu galery={true}>klimatyzacja</Submenu>
        </div>
      </div>
    </div>
  )
}

export default Klimatyzacja
