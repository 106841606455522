import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Submenu = ({ more = true, galery = false, film = false, children }) => {
  return (
    <div className="Submenu">
      <Link to={"/" + children}>
        <StaticImage
          src="../../assets/images/oze/submenu/more.png"
          alt="more"
          class="subMenuIcon"
        />
      </Link>
      {film && (
        <StaticImage
          src="../../assets/images/oze/submenu/film.png"
          alt="film"
          class="subMenuIcon"
        />
      )}
      {galery && (
        <Link to={"/" + children + "_galeria"}>
          <StaticImage
            src="../../assets/images/oze/submenu/galeria.png"
            alt="galery"
            class="subMenuIcon"
          />
        </Link>
      )}
    </div>
  )
}

export default Submenu
