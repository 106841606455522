import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import SideMenu from "../SideMenu"

import "../../assets/css/oze.css"

const OzeMenu = () => {
  return (
    <>
      <div className="menu">
        <AnchorLink
          to="/ekologiczna_energia#pompy_ciepla"
          className="menuElement fiveElementMenu"
        >
          <StaticImage
            src="../../assets/images/oze/menu/pompa_ciepla.png"
            alt="pompa_ciepla"
            placeholder="tracedSVG"
          />
          <h3>pompy ciepła</h3>
        </AnchorLink>
        <AnchorLink
          to="/ekologiczna_energia#fotowoltaika"
          className="menuElement fiveElementMenu"
        >
          <StaticImage
            src="../../assets/images/oze/menu/fotowoltaika.png"
            alt="fotowoltaika"
            placeholder="tracedSVG"
          />
          <h3>fotowoltaika</h3>
        </AnchorLink>
        <AnchorLink
          to="/ekologiczna_energia#klimatyzacja"
          className="menuElement fiveElementMenu"
        >
          <StaticImage
            src="../../assets/images/oze/menu/klimatyzacja.png"
            alt="klimatyzacja"
            placeholder="tracedSVG"
          />
          <h3>klimatyzacja</h3>
        </AnchorLink>
        <AnchorLink
          to="/ekologiczna_energia#smart_dom"
          className="menuElement fiveElementMenu"
        >
          <StaticImage
            src="../../assets/images/oze/menu/smart_dom.png"
            alt="smart_dom"
            placeholder="tracedSVG"
          />
          <h3>inteligentne domy</h3>
        </AnchorLink>
        <AnchorLink
          to="/ekologiczna_energia#kompensatory"
          className="menuElement fiveElementMenu"
        >
          <StaticImage
            src="../../assets/images/oze/menu/kompensatory.png"
            alt="kompensatory"
            placeholder="tracedSVG"
          />
          <h3>
            kompensatory
            <br />
            mocy biernej
          </h3>
        </AnchorLink>
      </div>
      <SideMenu base="ekologiczna_energia#oze">
        <div className="sideMenuElement">
          <h3>pompy ciepła</h3>
          <AnchorLink to="/ekologiczna_energia#pompy_ciepla">
            <StaticImage
              src="../../assets/images/oze/menu/pompa_ciepla.png"
              alt="pompa_ciepla"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>fotowoltaika</h3>
          <AnchorLink to="/ekologiczna_energia#fotowoltaika">
            <StaticImage
              src="../../assets/images/oze/menu/fotowoltaika.png"
              alt="fotowoltaika"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>klimatyzacja</h3>
          <AnchorLink to="/ekologiczna_energia#klimatyzacja">
            <StaticImage
              src="../../assets/images/oze/menu/klimatyzacja.png"
              alt="klimatyzacja"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>inteligentne domy</h3>
          <AnchorLink to="/ekologiczna_energia#smart_dom">
            <StaticImage
              src="../../assets/images/oze/menu/smart_dom.png"
              alt="smart_dom"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>
            kompensatory
            <br />
            mocy biernej
          </h3>
          <AnchorLink to="/ekologiczna_energia#kompensatory">
            <StaticImage
              src="../../assets/images/oze/menu/kompensatory.png"
              alt="kompensatory"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
      </SideMenu>
    </>
  )
}

export default OzeMenu
