import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Submenu from "./Submenu"

const Kompensatory = () => {
  return (
    <div id="kompensatory">
      <div className="ozeElement">
        <StaticImage
          src="../../assets/images/oze/kompensatory.png"
          alt="kompensatory"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <div className="blackBorderLeft">
            <h3>
              KOMPENSATORY
              <br />
              MOCY BIERNEJ
            </h3>
            <p>
              Pomagają w zoptymalizowaniu zużycia prądu. Dzięki wyeliminowaniu
              większego poboru mocy biernej, obniżamy swoje rachunki za prąd.
              Dodatkowo urządzenia kompensacyjne powodują obniżenie zużycia
              energii czynnej. Oferujemy kompleksowe usługi w tym zakresie.
            </p>
          </div>
          <Submenu>kompensacja</Submenu>
        </div>
      </div>
    </div>
  )
}

export default Kompensatory
