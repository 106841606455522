import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Submenu from "./Submenu"

const SmartDom = () => {
  return (
    <div id="smart_dom">
      <div className="ozeElement">
        <StaticImage
          src="../../assets/images/oze/smart_dom.png"
          alt="smart_dom"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <div className="blackBorderLeft">
            <h3>URZĄDZENIA SMART</h3>
            <p>
              W swojej ofercie posiadamy także systemy monitorujące. Zajmujemy
              się montażem alarmów, monitoringu, a także instalacjami typu SMART
              – umożliwiającymi sterowanie oświetleniem czy roletami (system
              AMPIO). W ofercie posiadamy również dodatkowe akcesoria,
              ułatwiające użytkowanie systemów alarmowych.
            </p>
          </div>
          <Submenu>smart_dom</Submenu>
        </div>
      </div>
    </div>
  )
}

export default SmartDom
